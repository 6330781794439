.radioTileGroup {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-8);
    justify-content: center;
}

.iconRadioTile {
    --_tile-background-color: var(--radiotile-background-unselected-idle);
    --_icon-color: var(--display-onlight-tertiary);

    display: inline-flex;
    gap: var(--space-16);
    align-items: center;
    min-block-size: var(--tube-height-lg);
    padding: 20px var(--space-24);
    background-color: var(--_tile-background-color);
    border-radius: var(--border-radius-2xl);
    color: var(--display-onlight-primary);
    cursor: pointer;

    &:hover {
        --_tile-background-color: var(--radiotile-background-unselected-onhover);
    }

    &.checked {
        --_icon-color: currentcolor;
        --_tile-background-color: var(--tile-bg-checked);
    }

    &:has(:focus-visible) {
        box-shadow: var(--shadow-focus);
    }

    &:has(:active):not(.checked) {
        transform: var(--card-active-transform);
    }
}

.seaSalt {
    --tile-bg-checked: var(--radiotile-background-selected-sea-salt);
}

.green {
    --tile-bg-checked: var(--radiotile-background-selected-green);
}

.red {
    --tile-bg-checked: var(--radiotile-background-selected-red);
}

.yellow {
    --tile-bg-checked: var(--radiotile-background-selected-yellow);
}

.icon {
    color: var(--_icon-color);
}

.input {
    position: absolute;
    block-size: 1px;
    inline-size: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
